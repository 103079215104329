<template>
        <div class="broadcasts page">
            <div v-if="history.length > 2" class="jump-back">
                <div class="content-g" style="padding-bottom: 0">
                <h3 style="padding-bottom: 20px; width: 100%;">
                    <div>Jump Back In</div>
                    <router-link to="/epg" class="epglink" v-ripple> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none"> <path d="M22 17.5L18.5 20V15L22 17.5Z" fill="#fff" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 5L20 5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 11L20 11" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 17L14 17" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                        Program Guide </router-link>
                </h3>
                <div class="content-row" style="margin-bottom: 0">
                    <div class="mason" v-for="(h, index) in Array.from({length: Math.floor(history.length / 3)})" :key="index">
                        <div class="stack">
                            <div>
                                <MixedHistory size="fit" :history="history[(index  * 3)]"></MixedHistory>
                            </div>
                            <div>
                                <MixedHistory size="fit" :history="history[(index * 3) + 1]"></MixedHistory>
                            </div>
                        </div>

                        <div class="full">
                            <div>
                                <MixedHistory size="fit" :history="history[(index * 3) + 2]"></MixedHistory>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="saved.length > 0">
            <div class="content-g">
                    <h3 style="padding-bottom: 20px;"> Saved </h3>
                    <div class="content-row" style="margin-bottom: 0">
                        <div v-for="(c, index) in saved" :key="index">
                            <SavedChannel :channel="c"></SavedChannel>
                        </div>
                    </div>
                </div>
            </div>

            <div class="headers">
                <h1> TV </h1>
                <div class="section">
                    <div class="content">
                    <div class="section-filters no-fill">

                        <div class="toggle">
                            <div class="item"  v-ripple :class="isActive('USA')" @click="changeCountry('USA')">
                                <img src="https://flagicons.lipis.dev/flags/4x3/um.svg"/>
                                <span> US </span>
                            </div>

                            <div class="item" v-ripple :class="isActive('CA')" @click="changeCountry('CA')">
                                <img src="https://flagicons.lipis.dev/flags/4x3/ca.svg"/>
                                <span> CA </span>
                            </div>

                            <div class="item" v-ripple :class="isActive('UK')" @click="changeCountry('UK')">
                                <img src="https://flagicons.lipis.dev/flags/4x3/gb.svg"/>
                                <span> UK </span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <Transition name="fade">
                <div class="content-row guides" v-if="ready">
                    <Guide v-ripple v-for="(e, i) in epgs" :key="i + activeCountry + e.id" class="content-guide" :guide="e" />
                </div>
            </Transition>


            <h3 class="sports" v-if="ready && sports.length > 0 && activeCountry === 'USA'" >
                Live Sports

              <router-link to="/sports" v-ripple>
                see all
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M8 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H3.6C3.26863 3 3 3.26863 3 3.6V16" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 6L18 6" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M6 6H7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3.5 20.5L12 12M12 12V16M12 12H8" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
              </router-link>
            </h3>

            <Transition name="fade">
                <div class="content-row guides" v-if="ready && sports.length > 0 && activeCountry === 'USA'">
                    <Sport v-ripple v-for="(s, i) in sports" :key="i + activeCountry + s.id"  :sport="s" mode="live" />
                </div>
            </Transition>

            <div v-for="(g, j) in channelGroups[activeCountry]" :key="j + activeCountry + g.id">
                <Transition name="fade">
                    <div class="content-rows" v-if="ready">
                        <div v-for="(e, i) in epgs.slice((j*2), (j*2 + 2))" :key="i + activeCountry + e.id"  class="content-row">
                            <Listings v-ripple :guide="e" />
                        </div>
                    </div>
                </Transition>

                <div class="content-g">
                    <Transition name="fade">
                        <h3 v-if="ready"> {{ g[0].group }} </h3>
                    </Transition>
                    <Transition name="fade">
                        <div class="content-row" v-if="ready">
                            <div v-for="(c, index) in g" :key="index + activeCountry + c.id" class="content-guide">
                                <Channel v-ripple :channel="c" />
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>

            <Transition name="fade">
                <h3 style="padding-right: 10px"> Live Stations
                <router-link to="/stations" v-ripple>
                    see all
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M8 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H3.6C3.26863 3 3 3.26863 3 3.6V16" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 6L18 6" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M6 6H7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3.5 20.5L12 12M12 12V16M12 12H8" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </router-link>


            </h3>
            </Transition>
            <Transition name="fade">
                <div class="content-row">
                    <div class="content-e">
                        <div v-for="(c, index) in stfs.slice(0,76)" :key="index + activeCountry" class="content-guide"> <Channel v-ripple :channel="c" /> </div>
                    </div>
                </div>
            </Transition>
        </div>
</template>
<script>

// @ is an alias to /src
const Sport = () => import('@/components/Sport')
const Channel = () => import('@/components/Channel')
const MixedHistory = () => import('@/components/MixedHistory')
const Guide = () => import('@/components/ContentGuide')
const Listings = () => import('@/components/ListGuide')
const SavedChannel = () => import('@/components/SavedChannel')

export default {
  name: 'Broadcasts',

  components: {
    Guide,
    Channel,
    MixedHistory,
    SavedChannel,
    Listings,
    Sport
},

  watch: {
  },

  computed: {
        stfs: function(){
            return window.shuffle(this.tfs)
        }

  },

  data: function(){
    return {
        epgs: [],
        saved: [],
        history: [],
        sports: [],
        channelGroups: [],
        ready: false,
        listOpen: false,
        activeCountry: 'USA',
        tfs: [],
        channels: {USA: [], CA: [], UK: [], TFS: []},
        offset: 1,
    }
  },

  mounted: async function(){
    if(window.location.href.split('#')[1]){
        this.activeCountry = window.location.href.split('#')[1]
    }

    this.loadChannels()
    this.loadGuides()
    this.loadTFS()
  },

  activated: async function(){
    const req = await fetch(process.env.VUE_APP_SERVER + '/history')
    const res = await req.json()
    this.history = window.shuffle(res.history)
    this.saved = res.saves

    this.loadSports()
  },
  methods: {
    loadGuides(){
        fetch(process.env.VUE_APP_SERVER + '/guides').then(async (d) => {
        const eresponse = await d.json()
        this.epgs = eresponse.guides
        })
    },


    loadSports(){
        fetch(process.env.VUE_APP_SERVER + '/liveSports').then(async (data) => {
            const sports = await data.json()
            this.sports = sports.live
            console.log(this.sports)
        })
    },

    loadTFS(){
        fetch(process.env.VUE_APP_SERVER + '/channels?country=24/7').then(async (data) => {
            const channels = await data.json()
            this.tfs = channels['TFS']
        })
    },
    loadChannels(){
        fetch(process.env.VUE_APP_SERVER + '/channels?country=' + this.activeCountry).then(async (data) => {
            const channels = await data.json()
            this.channels[this.activeCountry] = channels[this.activeCountry]
            this.setChannelGroups()
        })
    },

    groupBy(arr, key) {
        return arr.reduce((result, obj) => {
            const groupKey = obj[key];
            if (!result[groupKey]) {
            result[groupKey] = [];
            }
            result[groupKey].push(obj);
            return result
        }, {});
    },

    changeCountry(country){
          if(country == this.activeCountry){ return }
          window.history.pushState('', country,'#' + country)
          this.ready = false
          this.activeCountry = country

            this.epgs = window.shuffle(this.epgs)
            this.tfs = window.shuffle(this.tfs)
          this.$nextTick(() => {



            if(this.channels[this.activeCountry].length === 0){
                this.loadChannels()
            }else{
                this.ready = true
            }

          })
    },

    isActive(country){
           if(country == this.activeCountry){
               return 'active'
           }
    },

    setChannelGroups(){
        this.channelGroups[this.activeCountry] = Object.values(this.groupBy(this.channels[this.activeCountry], 'group')).sort((a,b) => {
            if (a[0].group < b[0].group) {
                return -1;
            }
            if (a[0].group > b[0].group) {
                return 1;
            }
            return 0;


        })
        this.ready = true
    },

  }
}
</script>

<style scoped lang="scss">

.content-g{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    > div{
        margin-bottom: 20px;
    }
}

.toggle{
    display: flex;
    .item{
        display: flex;
        margin: 5px;
        height: 35px;
        padding:  0 10px;
        justify-content: center;
        align-items: center;
        transition-duration: 0.3s;

        span{
            margin: 0 10px;
            font-weight: bold;
            color: black;
            font-size: 12px;
            display: none;
        }

        img{
            width: 20px;
        }

        &.active{
            background-color: white;
            transition-duration: 0.3s;
            border-radius: 3px;
            img{
                border-radius: 2px;
            }
            span{
                display: block;
            }
        }
    }

}

.section-filters{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.broadcasts{
    h3{
        padding: 10px 20px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.sports{
            margin-top: -20px;
            padding-bottom: 20px
        }
        a{
            font-size: 11px;
            font-weight: normal;
            color: white;
            display: flex;
            align-items: center;
            svg{
                padding-left: 10px;
                width: 12px;
                height: 12px;
            }
        }

        button{
            background-color: transparent;
            outline: none;
            box-shadow: none;
            border-radius: 4px;
            border: 1px solid white;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 20px;

            svg{
                padding: 5px 10px;
            }

        }
    }
}

.headers{
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.guides{
    padding-bottom: 20px

}
.content-e{
    display: flex;
    min-width: 6100px;

    flex-wrap: wrap;
    > div {
        margin-right: 5px;
        margin-bottom: 10px;
    }

}
.mason{
    display: flex;
    flex-direction: row;
    height: 300px;
    min-width: calc(100vw - 40px);
    margin-right: 10px;
    .full{
        width: 50%;
        div{
            height: 100%;
        }
    }
    .stack{
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        div{
            height: 50%;
            &:first-of-type{
                margin-bottom: 10px;
            }
        }
    }

}

.content-rows .content-row{
    margin-bottom: 10px;
}
.epglink{
    padding: 10px 10px;
    border: 1px solid white;
    display: block;
    border-radius: 5px;
    svg{
        margin: 0;
        margin-right: 10px;
        padding: 0;
    }

}

</style>
